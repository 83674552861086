.card_with_delete{
    display: flex;
    //align-items: center;

    &_icon{
        height: 24px;
        width: 24px;
        // margin-top: 1.0rem;
        margin-right: 0.5rem;
        margin-left: 0.25rem;
        cursor: pointer;
        
    }
}