.full__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left__container,
.right__container__upper, .right__container__lower {
    border: 1px solid lightgrey;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    width: 380px;
    margin-bottom: 0.5rem;
    background-color: white;
}

/* Media query for smaller screens */
@media screen and (max-width: 667px) {
    .left__container,
    .right__container__lower, .right__container__upper {
        width: 330px; /* Adjust the width to 330px for screens under 350px */
    }
}

.ticket__info__container {
    display: flex;
    justify-content: space-around;
    width: 100%;

    &__stats{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.bottom__container {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    width: 100%;
    background-color: white;
}

.card__image {
    width: 15rem;
    height: 8rem;
    border-radius: 12px;
    background-color: black; 
    margin: 0.5rem;
    &:hover {
        cursor: pointer;
    }
}

.table__metrics__revenue {
    &__container {
        color: green;
        padding: 0.25rem 0.5rem 0.25rem 0.5rem;
        border-radius: 8px;
        background: rgba(21, 206, 0, 0.2);
        width: fit-content;
    }

    &__text {
        margin: 0;
    }
}

.total__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.top__container {
    display: flex;  /* Add this line to make it a flex container */
    flex-direction: column;
}

/* Add a media query for larger screens */
@media screen and (min-width: 601px) {
    .top__container {
        flex-direction: row;
        gap: 0.5rem;
    }

    .bottom__container {
        width: 100%; /* Set the width to 100% to match the top container */
    }
}

/* Add a media query for smaller screens */
@media screen and (max-width: 600px) {
    .top__container {
        flex-direction: column;
    }

    .bottom__container {
        width: 100%; /* Set the width to 100% to make it a full-width column */
    }
}

