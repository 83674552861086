.emailForm{
    display: flex;
    flex-direction: column;
    &_button{
        width: 55%;
        margin: 4px;
    }
    &_to{
        border-radius: 4px;
        border: 1px solid grey;
        width: 98%;
    }
    &_text{
        margin: 4px;
        width: 100%;
        &_input{
          margin: 4px; 
          width: 98%; 
          height: 5rem;
          border-radius: 4px;
        }
    }
}