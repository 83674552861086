.add__rate__input {
  display: flex;
}

/* Style for each rate entry */
.rate-entry {
  display: flex;
  /* Display rate text and action icons in a row */
  align-items: center;

  /* Vertically center items in the row */
  margin-bottom: 1.25rem;
  /* Add margin between rate entries */
}

/* Style for the rate text */
.rate-text {
  flex: 1;
  /* Allow rate text to grow and take available space */
  font-size: 16px;
  /* Adjust the font size as needed */
  width: fit-content;
}

/* Style for the action icons */
.rate__action__icons {
  margin-left: 0.75rem;
  display: flex;
  /* Display action icons in a row */
  gap: 35px;
  /* Add some space between icons */
  display: flex;
}

/* Style for the action icons (individual icons) */
.rate__action__icons img {
  width: 22px;
  /* Adjust the icon width as needed */
  height: 22px;
  /* Adjust the icon height as needed */
}

.edited__rate {
  width: 4.5rem;
  height: 1.5rem;
  border-radius: 4px;
  margin-right: 1rem;
}

.rate__amount {
  margin: 0;
  margin-right: 1rem;
  padding: 0;
  width: 1rem;
  height: 1.5rem;
  display: inline;
}

/* Style for the Save icon */
.save-icon {
  font-size: 24px;
  /* Adjust the icon size as needed */
  cursor: pointer;
  margin-left: 10px;
  /* Add some spacing to separate the icon from the Edit icon */
}

.modal__footer{
  margin: 0 0.75rem 1.5rem 0;
}
