.company__title{
    margin: 1rem 0.25rem 0rem 0.25rem;
}

.company__garage__list{
    margin-right: 1.5rem;
    padding-left: 1.5rem;
    &__item{
        display: flex;
        justify-content: space-between; 
        align-items: center;
        border-bottom: 1px solid lightgray;
    }
}

.add__new__garage{
    margin-bottom: -1rem;
    padding: 0;
    margin-left: 0.25rem;
}

.editGarage__icon{
    margin-right: 0.75rem;
}