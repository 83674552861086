// .add_delete_buttons{
//     // display: flex;
//     margin-bottom: 0.75rem;
// }

// .add_button{
//     margin-right: 2rem;

// }
// .resource_button{
//     margin-bottom: 1rem;
// }

.add_folder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    &_button{
        margin-right: 0.25rem;
    }
}