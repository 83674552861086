.pie__chart__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 320px;
    @media (max-width: 500px) {
        margin: auto;
    }
}

.transients__container{
    display: flex;
    justify-content: space-around;
    @media (max-width: 500px) {
        flex-direction: column;
    }
}

