.input{
    border: 2px solid gainsboro;
    border-radius: 8px;
}

.user-input{
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    width: 75%;
    
}

.new-user-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__header{
        margin-top: 1rem;
        margin-bottom: 0;
    }
}

.type-radio{
    margin: 0 1rem 0 1rem;
}

.radio-selectios{
    display: flex;
    //flex-direction: column;
    //align-items: flex-start;
}

.radio-buttons-title{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.create-user-button{
    margin-top: 0.5rem;
}