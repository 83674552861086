.lex__hotel__container{
   /// margin: 0.5rem;
    &__title{
        display: flex;
        justify-content: center;
    }
    &__button{
        margin: 0.75rem 0 0.75rem 0.5rem;
    }
}

.lex__hotel__table__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title{
        margin: 0;
        padding: 0;
    }
    &__header{
        display: flex;
        align-items: center;
        padding: 0;
    }
}