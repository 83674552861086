.user__list{
    padding-right: 1.25rem;
}
.edit__user{
    display: flex;
    margin-bottom: 4px;
    padding-bottom: 4px;
    border-bottom: 2px solid lightgray;
    align-items: center;
    justify-content: space-between;
    &__button{
        margin-left: 1rem;
        &:hover{
            cursor: pointer;
        }
    }

}