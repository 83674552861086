.filter-by-rate__header{
    margin: 0.5rem 0 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
}

.rate-table-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text{
        margin: 0;
    }
}

.filter-by-rate-buttons{
    display: flex;
    margin-bottom: 0.5rem;
}

.display__errors__button{
    margin-left: 12px;
}