.login{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo{
    margin-top: 4rem;
    margin-bottom: 1rem;
    height: 7rem;
    // width: min-content;
}

.wiki{ 
    margin-top: 0.5rem;
}