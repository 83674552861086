.schemehorn{
    font-size: 13px;
}

.disc{
    color: red !important;
    
}

.disc::before{
    content: "(";
}

.disc::after{
    content: ")";
}

.schemerhorn__summary__header {
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    flex-wrap: wrap; /* Allow text to wrap if necessary */
    margin: 0;
    padding: 0;
    text-align: center; /* Center-align the text */
}
  
  