.badgeContainer {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.userNameBadge {
    background: aliceblue;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    &:hover {
        cursor: pointer;
        background-color: powderblue;
        color: white;
    }
}

.initials {
    margin: 0;
}

.dropdown-toggle::after {
    content: none !important;
}

.initials {
    margin: 0;
}

.dropdown-toggle::after {
    content: none !important;
}

.dropdown-toggle::after {
    content: none !important;
}
