.type{
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 0.25rem;
    width: fit-content;

    &__picker{
        padding-left: 0.5rem !important;
        padding-right: 0.25rem !important;

    }
}