.expanded-row{
    padding: 0 !important;
    border-bottom-width: 0px !important;
}

.expandIcon{
    height: 25px;
    width: 25px;
    object-fit: cover;
    cursor: pointer;

}

.scheduled__emails__header{
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}