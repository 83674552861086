.report__container {
    margin: auto;
    max-width: 768px;
 }

body {
    margin: 0; /* Reset default margin for body */
    padding: 0; /* Reset default padding for body */
    background-color: #daecf2 !important; /* Set your desired background color */
    overflow-y: scroll;
}

tr{
    background-color: white !important;
}