.last__update{
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.update__loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error__row{ 
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
}

.error__dates{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    &:hover{
        cursor: pointer;
        background-color:lightgray;
    }
}
