.table-font {
    font-size: 13px;
}

.selectors {
    display: flex;
    //align-items: center;
    justify-content: flex-start;
};

.red {
    background: rgba(221, 49, 49, 0.6) !important;
}

.green {
    background: rgb(5, 156, 5, 0.6) !important;
}

.orange {
    background: rgb(233, 159, 21, 0.8) !important;
}

.wait-times-pickers {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    max-width: 768px;
    margin: auto;
}

.heading {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
}

.wait-table {
    max-width: 768px;
    min-width: 320px;
    margin: auto;
    margin-top: 0.5rem;
}

.report-button {
    margin: 0.5rem;
    margin-top: 0.5rem;
}

.button__container{
    display: flex;
    align-items: center;
    justify-content: center;
}
