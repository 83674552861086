.custom-table {
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

.daily-report__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.25rem 0 0.5rem 0;

    &__text {
        margin: 0;
    }

}

.pdf-button {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.toolbar__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    @media (max-width: 768px){
        display: none;
    }


    &__mobile {
        display: flex;
        justify-content: space-around;
        margin: 1rem;
        @media (min-width: 768px) {
            display: none;
        }
    }
}