// .edited__image__input{
//     margin-left: 0.5rem;
//     border: 1px solid gray;
//     border-radius: 6px;
//     width: 3rem;
// }

.edited__database-connection__input{
    margin-left: 0.5rem;
    border: 1px solid gray;
    border-radius: 6px;
    width: 10rem;   
}