.filtered-date-picker{
    margin: 10.5rem !important;
}
.filtered-button{
    margin-left: 0.5rem;
    margin-top:0.5rem;
}
.report_title{
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 0.5rem 0;
}

.automated-filtered-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text{
        margin: 0;
    }
}

.transient__data__title{
    display: flex;
    justify-content: center;
}