.pallet__table__title{
    margin: 1rem;
    display: flex;
    justify-content: center;
}

.side__level__selectors{
    display: flex;
    justify-content: flex-start;
    margin: 1.5rem;

}

.level__selector{
    background-color: white;
}